
import './Impostos.css';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import api from '../../api.js';
import ModalDialog from '../Modal/Modal-Dialog.jsx';
import ModalImposto from '../Modal/Modal-Imposto.jsx';
import { color, styled } from '@mui/system';

const Impostos = () => {

    const date = new Date();
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const [primeiroDiaString, setPrimeiroDiaString] = useState(`${primeiroDia.getFullYear()}-${(primeiroDia.getMonth() + 1).toString().length < 2 ? `0${primeiroDia.getMonth() + 1}` : primeiroDia.getMonth() + 1}-${primeiroDia.getDate().toString().length < 2 ? `0${primeiroDia.getDate()}` : primeiroDia.getDate()}`);
    const [lastDayDate, setLastDayDate] = useState(`${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().length < 2 ? `0${lastDay.getMonth() + 1}` : lastDay.getMonth() + 1}-${lastDay.getDate().toString().length < 2 ? `0${lastDay.getDate()}` : lastDay.getDate()}`);
    const [openModalNova, setOpenModalNova] = useState(false);
    const [total, setTotal] = useState(0);
    const [filtro, setFiltro] = useState("0");
    const [impostos, setImpostos] = useState([]);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [operacao, setOperacao] = useState("");
    const [origem, setOrigem] = useState("");
    const [titulo, setTitulo] = useState("");

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '& .row-color-red': {
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
                backgroundColor: 'darkred',
            },
        },
        '& .row-color-green': {
            backgroundColor: 'rgb(162, 243, 159)',
            color: 'rgb(255, 255, 255)',
            '&:hover': {
                backgroundColor: 'rgb(51, 67, 100)',
                color: '#fff'
            },
        },
        '& .row-color-white': {
            backgroundColor: 'white',
            color: 'white',
            '&:hover': {
                backgroundColor: 'rgb(51, 67, 100)',
            },
        },
        '& .cell-color-blue': {
            backgroundColor: 'blue',
            color: 'white',
            '&:hover': {
                backgroundColor: 'darkblue',
            },
        },
        '& .Mui-selected': {
            //   backgroundColor: '#fff !important',
            //   color: '#000 !important',
        }
    }));

    function handleCloseModalNova() {
        setOpenModalNova(false);
        handleFiltro();
    }

    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'data',
            headerName: 'Data',
            width: 100,
            agn: "center",
            type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        { field: 'descricao', headerName: 'Descricao', width: 300 },
        { field: 'valor', headerName: 'Valor', width: 120 },
        { field: 'status', headerName: 'Status', width: 120 },
        {
            field: 'baixar',
            headerName: '-',
            width: 100,
            renderCell: (params) => (
                <button className='btn-enviar'
                    style={{ display: params.row.status === 'Pago' ? 'none' : 'inline-block' }}
                    onClick={() => handleOpenModalDialog(params.id)}>
                    Baixar
                </button>
            )
        },
        {
            field: "excluir",
            headerName: '-',
            width: 100,
            renderCell: (params) => (
                <button className='btn-cancelar' 
                        style={{ display: params.row.status === 'Pago' ? 'none' : 'inline-block' }}  
                        onClick={() => handleButtonExcluir(params.id, params)}>
                    Excluir
                </button>
            )
        },
        {
            field: "pdf",
            headerName: '-',
            width: 100,
            renderCell: (params) => (
                <button className='btn-pdf' onClick={() => handleMostrarDoc(params.row)}>
                    DOC
                </button>
            )
        }

    ];

    const handleMostrarDoc = (r) => {
        window.open(r.caminho_http_doc, '_blank'); // Abre o link em uma nova aba
      //  alert(JSON.stringify(r.caminho_http_doc));
    }

    // Select impostos
    useEffect(() => {
        (async () => {
            try {
                const { data: impostos } = await api.get("/impostos/" + primeiroDiaString + "/" + lastDayDate + "/Todos");
                if (impostos) {
                    setImpostos(impostos);
                    let iptot = 0;
                    impostos.map((imp) => {
                        iptot = iptot + parseFloat(imp.valor);
                    });
                    let numeroComDecimais = iptot.toFixed(2);
                    let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                    setTotal(numeroFormatadoComDecimais);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [setImpostos]);


    function handleOpenModalDialog(e) {
        setSelectedId(e);
        setOperacao("Baixa");
        setOrigem("Imposto");
        setTitulo("Confirma a baixa do imposto ?");
        setOpenModalDialog(true);
    }

    function handleButtonExcluir(event,st) {
        setOperacao("Excluir");
        setOrigem("Imposto");
        setTitulo("Confirma a exclusão do imposto ?");        
        setOpenModalDialog(true);
    }

    const handleFiltro = async (e) => {
        let status = "Todos";
        if (filtro === "1") {
            status = "Pendente"
        }
        if (filtro === "2") {
            status = "Pago"
        }
        try {
            const { data: impostos } = await api.get("/impostos/" + primeiroDiaString + "/" + lastDayDate + "/" + status);
            if (impostos) {
                setImpostos(impostos);
                let iptot = 0;
                impostos.map((imp) => {
                    iptot = iptot + parseFloat(imp.valor);
                });
                let numeroComDecimais = iptot.toFixed(2);
                let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                setTotal(numeroFormatadoComDecimais);
            }
        } catch (error) {
            console.log(error);
        }
    }

    function handlePrimeiraData(e) {
        const resultArray = e.substring(0, 10) + ' 00:00:01';
        setPrimeiroDiaString(resultArray);
    }

    function handleUltimaData(e) {
        const resultArray = e.substring(0, 10) + ' 23:59:59';
        setLastDayDate(resultArray);
    }

    const filtroStatus = ['Todos', 'Pendentes', 'Pagos'];

    function handleSetStatus(e) {
        setFiltro(e);
    }

    function handleCloseModalDialog() {
        setOpenModalDialog(false);
    }

    const handleBaixarImposto = async () => {
        setOpenModalDialog(false);
        try {
            await api.put("/imposto/" + selectedId + "/Pago");
            handleFiltro();
        } catch (error) {
            console.log(error);
        }
    }

    const handleExcluirImposto = async () => {
        setOpenModalDialog(false);
        alert('Excluir');
    }

    return (
        <div className='container-impostos'>
            <ModalImposto statusModal={openModalNova} onCloseModal={handleCloseModalNova} selectedId={selectedId}></ModalImposto>
            <ModalDialog statusModal={openModalDialog} onCloseModalDialog={handleCloseModalDialog} onBaixarImposto={handleBaixarImposto} onExcluirImposto={handleExcluirImposto} selectedId={selectedId} titulo={titulo} origem={origem} operacao={operacao}></ModalDialog>
            <div className="imposto-top">
                <div className="imposto-column-top">
                    <h2 style={{ color: '#fff' }} >Relação de Impostos</h2>
                </div>
                <div className="imposto-column-top-btn">
                    <button onClick={handleOpenModalNova}>Novo</button>
                </div>
            </div>
            <div className="imposto-filtro">
                <h2 style={{margin:10}}>Filtros: </h2>
                <TextField
                    id="datetime-inicio"
                    label="Inicial"
                    type="date"
                    defaultValue={primeiroDiaString}
                    onChange={(e) => handlePrimeiraData(e.target.value)}
                    variant="filled"
                    className="imp-custom-textfield imp-custom-textfield-height"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <span><h3>até</h3> </span>
                <TextField
                    id="datetime-final"
                    label="Final"
                    variant="filled"
                    type="date"
                    defaultValue={lastDayDate}
                    onChange={(e) => handleUltimaData(e.target.value)}
                    className="imp-custom-textfield imp-custom-textfield-height"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <select className='imposto-btn-filtrar'
                    onChange={(e) => handleSetStatus(e.target.value)}
                    required
                >
                    {
                        filtroStatus.map((number, index) => (
                            <option key={index} value={index}>{number}</option>
                        ))
                    }
                </select>
                <button className='imposto-btn-filtrar' onClick={handleFiltro} >Filtrar</button>

                <div className='imposto-div-total'>
                    <h2>Total do filtro: R$ {total}</h2>
                </div>
            </div>

            <div className="imposto-table">
                {/* <DataGrid */}
                <StyledDataGrid
                    rows={impostos}
                    columns={columns}
                    getRowClassName={(params) =>
                        params.row.status === 'Pendente' ? 'row-color-white' : 'row-color-green'
                    }
                    disableColumnResize={true}
                    // rowSpacing={3} // valor do espaçamento entre linhas em px
                    // columnSpacing={3} // valor do espaçamento entre colunas em px
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20, 50]}
                />
            </div>
        </div>
    );
}

export default Impostos;