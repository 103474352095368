import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { toast } from 'react-toastify';
import './Modal-imposto.css';
import { useEffect, useState } from "react";
import api from "../../api";
import UploadArquivo from '../Impostos/uploadArquivos.jsx';

function ModalImposto({ statusModal, onCloseModal, selectedId }) {

    const [descricao, setDescricao] = useState("");
    const [vencimento, setVencimento] = useState("");
    const [valor, setValor] = useState(0);
    const [tipo, setTipo] = useState("");

    useEffect(() => {
        (async () => {
            try {
                if (!selectedId) return;
                const { imposto } = await api.get("/imposto/" + selectedId);
                if (!imposto) return;
                if (imposto[0]) {
                    const obj = (imposto[0]);
                    const { descricao, data, valor, tipo } = obj;
                    setValor(valor);
                    setDescricao(descricao);
                    setVencimento(data);
                    setTipo(tipo);
                }
            } catch (e) {
                console.log("deu certo não", e);
            }
        })();
    }, [selectedId]);

    const handleClose = () => {
        setValor(0);
        setDescricao("");
        setVencimento("");
        setTipo("");
        onCloseModal();
    }

    const handleInserir = async () => {
        if (!descricao) {
            toast.error("Descrição deve ser preenchida")
            return;
        }
        if (!vencimento) {
            toast.error("Vencimento deve ser preenchido")
            return;
        }
        if (!valor) {
            toast.error("Valor deve ser preenchido")
            return;
        }
        try {
            const obj =
            {
                descricao: descricao,
                valor: valor,
                data: vencimento,
                status: 'Pendente',
                tipo: tipo
            }
            const response = await api.post("/createImposto", obj);
            handleClose();
        } catch (error) {
            console.error('Erro ao incluir os dados:', error);
        }
    }

    const tipos = ['Estadual', 'Federal', 'Municipal'];

    const onCloseDoc = (x) => {
        alert(x);
    }

    return (
        <div>
            <Dialog className="d-container" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>Lançamento Imposto</DialogTitle>
                <DialogContent className="dl-container" dividers>
                    <TextField
                        required
                        id="descricao"
                        label="Descrição"
                        margin="dense"
                        variant="standard"
                        fullWidth
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                    />
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                        <select className='imposto-tipo'
                            onChange={(e) => setTipo(e.target.value)}
                            required
                        >
                            {
                                tipos.map((number, index) => (
                                    <option key={index} value={index}>{number}</option>
                                ))
                            }
                        </select>
                        <TextField
                            required
                            id="valor"
                            label="Valor"
                            variant="standard"
                            margin="dense"
                            value={valor}
                            onChange={(e) => setValor(e.target.value)}
                        />
                        
                        <TextField
                            id="datetime-inicio"
                            label="Vencimento"
                            type="date"
                            defaultValue={vencimento}
                            onChange={(e) => setVencimento(e.target.value)}
                            variant="filled"
                            value={vencimento}
                            className="custom-textfield custom-textfield-height"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div>
                        {/* upload do arquivo */}
                        {/* <UploadArquivo onCloseDoc={onCloseDoc} /> */}
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Fechar</button>
                    <button className="btn-incluir" onClick={handleInserir}>Salvar</button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ModalImposto;