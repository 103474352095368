import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Janeiro', value: 400, label: 'Janeiro' },
  { name: 'Fevereiro', value: 300, label: 'Janeiro' },
  { name: 'Março', value: 300, label: 'Janeiro' },
  { name: 'Abril', value: 200, label: 'Janeiro' },
  { name: 'Maio', value: 290, label: 'Janeiro' },
  { name: 'Junho', value: 270, label: 'Janeiro' },
  { name: 'Julho', value: 250, label: 'Janeiro' },
  { name: 'Agosto', value: 280, label: 'Janeiro' },
  { name: 'Setembro', value: 210, label: 'Janeiro' },
  { name: 'Outubro', value: 200, label: 'Janeiro' },
  { name: 'Novembro', value: 250, label: 'Janeiro' },
  { name: 'Dezembro', value: 550, label: 'Janeiro' },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const ChartFaturamento = () => {
  return (
      <ResponsiveContainer>
        <PieChart>
          <Pie dataKey="value" data={data} fill="#8884d8" label />
        </PieChart>
      </ResponsiveContainer>
    // <PieChart width={400} height={400} >
    //   <Pie
    //     data={data}

    //     cx={200}
    //     cy={120}
    //     labelLine={false}
    //     outerRadius={100}
    //     fill="#8884d8"
    //     dataKey="value"
    //   >
    //     {data.map((entry, index) => (
    //       <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
    //     ))}
    //   </Pie>
    //   <Tooltip />
    //   <Legend />
    // </PieChart>
  );
}

export default ChartFaturamento;
