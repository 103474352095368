
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { toast } from 'react-toastify';
import './Modal-faturamento.css';
import { useEffect, useState } from "react";
import api from "../../api";


function ModalFaturamento({ statusModal, onCloseModal }) {

    const [clientes, setClientes] = useState([]);
    const [emitentes, setEmitentes] = useState([]);
    const [selectedEmitente, setSelectedEmitente] = useState(0);
    const [selectedCliente, setSelectedCliente] = useState(0);
    const [descricao, setDescricao] = useState("");
    const [valor, setValor] = useState(0);
    const [vencimento, setVencimento] = useState("");
    const [boleto, setBoleto] = useState("");
    const [nfse, setNfse] = useState("");

    // Select clientes
    useEffect(() => {
        (async () => {
            try {
                const { data: clientes } = await api.get("/clientes");
                if (clientes) {
                    setClientes(clientes);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    // Select emitentes
    useEffect(() => {
        (async () => {
            try {
                const { data: emitentes } = await api.get("/emitentes");
                if (emitentes) {
                    setEmitentes(emitentes);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    const handleClose = () => {
        setValor(0);
        setDescricao("");
        setSelectedCliente(0);
        setSelectedEmitente(0);
        onCloseModal();
    }

    return (
        <div>
            <Dialog open={statusModal} onClose={handleClose} scroll="paper" fullWidth>
                <DialogTitle>Controle de faturamento</DialogTitle>
                <DialogContent dividers>
                    <label>Emitente : </label>
                    <select className='select-item'
                        onChange={(e) => setSelectedEmitente(e.target.value)}
                        required
                        value={selectedEmitente}
                    >
                        <option key='99999' value='99999'>Escolha um Emitente</option>
                        {
                            emitentes.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                            ))
                        }
                    </select>
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <label>Cliente : </label>
                    <select className='select-item'
                        onChange={(e) => setSelectedCliente(e.target.value)}
                        required
                        value={selectedCliente}
                    >
                        <option key='99999' value='99999'>Escolha um Cliente</option>
                        {
                            clientes.map((cli) => (
                                <option key={cli.id} value={cli.id}>{cli.nome}</option>
                            ))
                        }
                    </select>
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <TextField
                        fullWidth
                        required
                        value={descricao}
                        id="descricao"
                        label="Descrição"
                        variant="filled"
                        onChange={(e) => setDescricao(e.target.value)}
                    />
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            required
                            type="number"
                            value={valor}
                            id="valor"
                            label="Valor"
                            variant="filled"
                            onChange={(e) => setValor(e.target.value)}
                        />
                        <TextField
                            required
                            type="date"
                            value={vencimento}
                            id="vencimento"
                            label="Vencimento"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setVencimento(e.target.value)}
                        />
                    </div>
                </DialogContent>
                <hr></hr>
                <DialogContent dividers>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            type="text"
                            value={boleto}
                            id="boleto"
                            label="N.Boleto"
                            variant="standard"
                        />
                        <TextField
                            type="text"
                            value={nfse}
                            id="nfse"
                            label="NFSe"
                            variant="standard"
                        />

                    </div>

                </DialogContent>
                <hr></hr>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Fechar</button>
                    {/* {operacao === 'Editar' && (
                        <button className="btn-incluir" onClick={handleUpdate}>Alterar</button>
                    )}
                    {operacao !== 'Editar' && (
                        <button className="btn-incluir" onClick={handleInserir}>Gravar</button>
                    )} */}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalFaturamento;