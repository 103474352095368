
function Lembrete(){
    localStorage.removeItem("sessionToken");
    localStorage.clear();
    return (
        <div className="">            
            <h1>Lembrete de senha</h1>
        </div>
    );
}

export default Lembrete;