import './home.css';
import FotoUserBlack from '../../img/avatar.png'
import { useState } from 'react';
import Compromissos from '../../components/Compromissos/Compromissos.jsx';
import { DataGrid } from '@mui/x-data-grid';
import ChartNfse from './GraficoNfse.jsx';
import ChartImpostos from './GraficoImpostos.jsx';
import ChartFaturamento from './GraficoFaturamento.jsx';
import NFSe from '../../components/Nfse/Nfse.jsx';
import Emitente from '../../components/Emitente/Emitente.jsx';
import Clientes from '../../components/Clientes/Clientes.jsx';
import Impostos from '../../components/Impostos/Impostos.jsx';
import Despesas from '../../components/Despesas/Despesa.jsx';
import Servicos from '../../components/Servicos/Servicos.jsx';
import Faturamento from '../../components/Faturamento/Faturamento.jsx';

function Home() {

    const [mostrarDivMeio, setMostrarDivMeio] = useState('compromisso');

    const alternarVisibilidade = (valor) => {
        setMostrarDivMeio(valor);
    };

    function handleReferencia(params) {
        console.log(params.target.value);
    }


    return <div className='home-container'>
        <div className="div-esquerda">
            <img src={FotoUserBlack} alt="" className="img-esquerda" />
            <hr />
            <div className="nav-esquerda">
                <ul id="ul-navbar">
                    <li>
                        <img width="94" height="94" src="https://img.icons8.com/3d-fluency/94/control-panel.png" alt="control-panel" />
                        <a href="#" onClick={() => alternarVisibilidade('dashboard')}>Dashboard</a>
                    </li>
                    <hr />
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/fluency/48/person-male.png" alt="person-male" />
                        <a href="#" onClick={() => alternarVisibilidade('emitente')} >Emitente</a>
                    </li>
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/manager.png" alt="manager" />
                        <a href="#" onClick={() => alternarVisibilidade('clientes')} >Clientes</a>
                    </li>
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/manager.png" alt="manager" />
                        <a href="#" onClick={() => alternarVisibilidade('servicos')} >Serviços</a>
                    </li>
                    <hr />
                    <li>
                        <img width="80" height="80" src="https://img.icons8.com/papercut/60/000000/document.png" alt="document" />
                        <a href="#" onClick={() => alternarVisibilidade('faturamento')} >Faturamento</a>
                    </li>
                    <li>
                        <img width="80" height="80" src="https://img.icons8.com/papercut/60/000000/document.png" alt="document" />
                        <a href="#" onClick={() => alternarVisibilidade('nfse')} >NFS-e</a>
                    </li>
                    <li>
                        <img width="64" height="64" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-tax-accounting-flaticons-lineal-color-flat-icons.png" alt="external-tax-accounting-flaticons-lineal-color-flat-icons" />
                        <a href="#" onClick={() => alternarVisibilidade('imposto')} >Impostos</a>
                    </li>
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/price-tag-euro.png" alt="price-tag-euro" />
                        <a href="#" onClick={() => alternarVisibilidade('despesa')} >Despesas</a>
                    </li>
                    <hr />
                    <li>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/price-tag-euro.png" alt="price-tag-euro" />
                        <a href="#" onClick={() => alternarVisibilidade('compromissos')} >Compromissos</a>
                    </li>
                    <li>
                        <img width="60" height="60" src="https://img.icons8.com/papercut/60/000000/chat.png" alt="chat" />
                        <a href="#" onClick={() => alternarVisibilidade('dashboard')} >Chat</a>
                    </li>
                </ul>

            </div>
            <div className='nav-baixo'>
                <img width="30" height="30" src="https://img.icons8.com/offices/30/facebook-new.png" alt="facebook-new" />
                <img width="30" height="30" src="https://img.icons8.com/cotton/64/whatsapp--v3.png" alt="whatsapp--v3" />
                <img width="30" height="30" src="https://img.icons8.com/color/48/email.png" alt="email" />
            </div>
        </div>

        {mostrarDivMeio === 'dashboard' && <div id='dashboard' className='div-meio'>
            <div className="div-meio-superior">
                <p className='p-select'>Empresa.: </p>
                <select className='ref-select'
                    onChange={handleReferencia}
                >
                    <option value="1">G-One Sistemas</option>
                    <option value="2">MPI Automação</option>
                    <option value="3">Manasses</option>
                </select>

                <p className='p-select'>Ano ref.: </p>
                <select className='ref-select'
                    onChange={handleReferencia}
                >
                    <option value="1">2024</option>
                    <option value="2">2023</option>
                    <option value="3">2022</option>
                    <option value="4">2021</option>
                </select>
                <button className='btn-select'>Filtrar</button>
            </div>
            <div className="div-meio-meio">
                <div className="nfse">
                    <h3 style={{display:'flex', justifyContent:'center'}}>Faturamento mensal</h3>
                    <ChartFaturamento />
                </div>
                <div className="impostos">
                    <h3 style={{display:'flex', justifyContent:'center'}}>Total de NFSe mensal</h3>
                    <ChartFaturamento />
                </div>

                <div className='div-xx'>
                    <button className='btn_meio'>Banco</button>
                    <button className='btn_meio'>Pró-labore</button>
                    <button className='btn_meio'>Boletos</button>
                </div>
            </div>
            <div className="div-meio-baixo">
                <div className='div-x'>
                    <h3 style={{display:'flex', justifyContent:'center'}}>Impostos mensais</h3>
                    <ChartImpostos />
                </div>
                <div className='div-x'>
                    <h3 style={{display:'flex', justifyContent:'center'}}>Despesas mensais</h3>
                    <ChartNfse />
                </div>
            </div>
        </div>}

        {mostrarDivMeio === 'faturamento' && <div className='div-meio'>
            <Faturamento />
        </div>
        }

        {mostrarDivMeio === 'nfse' && <div className='div-meio'>
            <NFSe />
        </div>
        }

        {mostrarDivMeio === 'emitente' && <div className='div-meio'>
            <Emitente />
        </div>
        }

        {mostrarDivMeio === 'clientes' && <div className='div-meio'>
            <Clientes />
        </div>
        }

        {mostrarDivMeio === 'imposto' && <div className='div-meio'>
            <Impostos />
        </div>
        }

        {mostrarDivMeio === 'despesa' && <div className='div-meio'>
            <Despesas />
        </div>
        }

        {mostrarDivMeio === 'servicos' && <div className='div-meio'>
            <Servicos />
        </div>
        }

        {mostrarDivMeio === 'compromissos' && <div className='div-meio'>
            <Compromissos />
        </div>
        }


    </div>
}

export default Home;