import { Navigate } from "react-router-dom";


function PriveteRoute(props){
    const logado = localStorage.getItem("sessionToken") ? true : false;
    // console.log(logado);
    
   /* return props.children;*/
    return logado ? props.children : <Navigate to="/login" />;
}

export default PriveteRoute;