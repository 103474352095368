import './Servicos.css';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import ModalServico from '../Modal/Modal-Servico.jsx';
import api from '../../api.js';
import { Box } from '@mui/material';

function Servicos() {

    const [openModalNova, setOpenModalNova] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [servico, setServico] = useState(null);

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'codigo', headerName: 'Codigo', width: 70 },
        { field: 'descricao', headerName: 'Descrição', width: 500, flex: 1 },
        { field: 'iss_retido', headerName: 'ISS Retido', width: 100 },
        { field: 'aliquota', headerName: 'Aliquota', width: 100 },
        {
            field: 'editar',
            headerName: 'Editar',
            width: 100,
            renderCell: (params) => (
                <button className='btn-enviar' onClick={() => handleButtonEditar(params.id)}>
                    Editar
                </button>
            )
        },
        {
            field: 'deletar',
            headerName: 'Excluir',
            width: 100,
            renderCell: (params) => (
                <button className='btn-excluir' onClick={() => handleButtonExcluir(params.id)}>
                    Excluir
                </button>
            )
        }
    ];

    function handleButtonExcluir(id) {
        alert(id);
    }

    function handleButtonEditar(id) {
        setSelectedId(id);
        setOpenModalNova(true);
    }

    function handleCloseModalNova() {
        setSelectedId(null);
        setOpenModalNova(false);
        api.get("/servicos").then(({ data }) => {
            if (data)
                setServico(data);
        }).catch((err) =>
            console.error("ops! ocorreu um erro" + err));
    }

    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/servicos");
                if (data)
                    setServico(data);
            } catch (e) {
                console.log("deu certo não");
            }
        })();
    }, []);

    return (
        <div className="container-servicos">
            <ModalServico statusModal={openModalNova} onCloseModal={handleCloseModalNova} selectedId={selectedId} ></ModalServico>

            <div className="container-top">
                <div className="column-top">
                    <h2 style={{ color: '#fff' }} >Cadastro de Serviços</h2>
                </div>
                <div className="column-top-btn">
                    <button onClick={handleOpenModalNova}>Novo</button>
                </div>
            </div>

            <div className="servico-meio">
                <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                    <DataGrid
                        autoHeight
                        rows={servico ?? []}
                        columns={columns}
                        disableColumnResize={true}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    );
}

export default Servicos;