import Select from 'react-select';
import { FormControl } from "@mui/material";
import { useState } from "react";

function Referencias() {

    const referencias = [
        { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
        { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
        { value: 'purple', label: 'Purple', color: '#5243AA' },
        { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
        { value: 'orange', label: 'Orange', color: '#FF8B00' },
        { value: 'yellow', label: 'Yellow', color: '#FFC400' },
        { value: 'green', label: 'Green', color: '#36B37E' },
        { value: 'forest', label: 'Forest', color: '#00875A' },
        { value: 'slate', label: 'Slate', color: '#253858' },
        { value: 'silver', label: 'Silver', color: '#666666' },
    ];

    const [selectReferencia, setSelectReferencia] = useState([]);

    return (
        <FormControl fullWidth margin="dense" variant="outlined">
            <Select
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "lightgray",
                        maxWidth: 400,
                        borderRadius: 10,

                    }),

                    placeholder: (defaultStyles) => { return { ...defaultStyles, color: "white" } },

                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                        return {
                            ...styles,
                            backgroundColor: isDisabled
                                ? undefined
                                : isSelected
                                    ? data.color
                                    : isFocused
                                        ? 'lightgray'
                                        : undefined,
                            color: isDisabled
                                ? '#ccc'
                                : isSelected
                                    ? 'white'
                                    : data.color,
                            cursor: isDisabled ? 'not-allowed' : 'default',
                        };
                    },
                }}
                defaultValue={selectReferencia}
                onChange={setSelectReferencia}
                options={referencias}
                placeholder='Mes de referencia'

            />
        </FormControl>
    );
}

export default Referencias;
