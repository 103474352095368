import React, { PureComponent } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'Janeiro',
        uv: 4000,
        pv: 2400,
        amt: 2210,    
      },
      {
        name: 'Fevereiro',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: 'Março',
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: 'Abril',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: 'Maio',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: 'Junho',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
 
];

export default class ChartImpostos extends PureComponent {
 
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
        //   width={500}
        //   height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 40,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="pv" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
          <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
