import { DataGrid } from '@mui/x-data-grid';
import './Emitente.css';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ModalEmitente from '../Modal/Modal-Emitente.jsx';
import api from '../../api.js';
import ModalDialog from '../Modal/Modal-Dialog.jsx';
import { styled } from '@mui/system';

function Emitente() {

    const [openModalNova, setOpenModalNova] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [emitente, setEmitente] = useState(null);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [atualiza, setAtualiza] = useState("");

    function handleCloseModalNova() {
        setSelectedId(null);
        setOpenModalNova(false);
        api.get("/emitentes").then(({ data }) => {
            if (data)
                setEmitente(data);
        }).catch((err) =>
            console.error("ops! ocorreu um erro" + err));
    }

    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    const RedCell = styled('div')({
        color: '#88240b',
        borderRadius: '15px',
        textAlign: 'Left'
    });

    const GreenCell = styled('div')({
        // backgroundColor: '#1f58af',
        color: '#0142f7',
        borderRadius: '15px',
        textAlign: 'Left'
    });

    const columns = [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'cnpj', headerName: 'CNPJ', width: 150, flex: 1, minWidth: 150 },
        { field: 'razao', headerName: 'Razão Social', width: 300, flex: 1, minWidth: 300 },
        { field: 'cep', headerName: 'CEP', width: 100, flex: 1 },
        { field: 'nome_cidade', headerName: 'Cidade', width: 150, flex: 1 },
        { field: 'nome_estado', headerName: 'Estado', width: 100, flex: 1 },
        {
            field: 'nfse', headerName: 'NFSe', width: 120,
            renderCell: (params) => {
                return params.row.nfse != "Habilitado" ? (
                    <RedCell>{params.row.nfse}</RedCell>
                ) : (
                    <GreenCell>{params.row.nfse}</GreenCell>
                );
            },
        },
        {
            field: 'editar',
            headerName: '',
            width: 100,
            renderCell: (params) => (
                <button className='btn-enviar' onClick={() => handleButtonEditar(params.id)}>
                    Editar
                </button>
            )
        },
        {
            field: 'habilitaNFSe',
            headerName: '',
            width: 150,
            renderCell: (params) => {
                // const isDisabled = params.row.nfse === 'Habilitado'; // Supondo que 'active' é o valor que habilita o botão
                return (
                    <button
                        className='btn-ativar'
                        onClick={() => handleOpenModalDialog(params.id, params.row.nfse)}
                    // disabled={isDisabled}
                    >
                        Habilita NFSe
                    </button>
                );
            }
        }
    ];

    function handleButtonEditar(id) {
        setSelectedId(id);
        setOpenModalNova(true);
    }

    function handleCloseModalDialog() {
        setOpenModalDialog(false);
    }

    function handleOpenModalDialog(id, status) {
        if (status === "Habilitado") {
            alert('Empresa já Habilitada para emitir NFSe');
        } else {
            setSelectedId(id);
            setOpenModalDialog(true);
        }
    }

    const handleAtivarEmpresa = async () => {
        setOpenModalDialog(false);
        alert('Vamos ativar a empresa na focusnfe');
        try {
            if (!selectedId) return;
            const response = await api.get("/emitente/" + selectedId);
            const emitente = response.data.emitente || response.data; // Ajuste conforme necessário

            if (!emitente) return;
            if (emitente[0]) {
                const obj = (emitente[0]);
                const { nome, razao, cnpj, cep, ibge, insc_municipal, ie, endereco, bairro, cidade, estado, numero, cod_tributario_municipio } = obj;
                const objEnvio =
                {
                    nome_fantasia: nome,
                    nome: razao,
                    cnpj: cnpj.replace(/[^0-9]/g, ''),
                    cep: cep,
                    ibge: ibge,
                    inscricao_municipal: insc_municipal,
                    inscricao_estadual: ie,
                    logradouro: endereco,
                    bairro: bairro,
                    numero: numero,
                    complemento: '',
                    telefone: '',
                    uf: estado,  // aqui tem q ser SP nao 35 por exemplo
                    municipio: cidade, // aqui tambem é o nome da cidade
                    cod_tributario_municipio: cod_tributario_municipio,
                    regime_tributario: 1,
                    enviar_email_destinatario: false,
                    email: '',
                    discrimina_imposto: true,
                    habilita_nfe: false,
                    habilita_nfce: false,
                    habilita_nfse: true
                }

                const response = await api.post('/empresaFocus', objEnvio);
                if (response) {
                    // pegar os token
                    const obj = {
                        token_homologacao: response.data.token_homologacao,
                        token_producao: response.data.token_producao,
                        id: selectedId
                    }
                    const upd = await api.put('/emitenteToken', obj);
                }

                setAtualiza(new Date());
            }
        } catch (error) {
            console.error('Erro ao incluir os dados:', error);
        }

    }

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/emitentes");
                if (data)
                    setEmitente(data);
            } catch (e) {
                console.log("deu certo não");
            }
        })();
    }, [atualiza]);
    

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/emitentes");
                if (data)
                    setEmitente(data);
            } catch (e) {
                console.log("deu certo não");
            }
        })();
    }, []);

    return (
        <div className='container-emitente'>
            <ModalEmitente statusModal={openModalNova} onCloseModal={handleCloseModalNova} selectedId={selectedId} ></ModalEmitente>
            <ModalDialog statusModal={openModalDialog} onCloseModalDialog={handleCloseModalDialog} ativarEmpresa={handleAtivarEmpresa} selectedId={selectedId} titulo='Confirma a ativação dessa empresa ?' origem='Emitente'></ModalDialog>
            <div className="emi-container-top">
                <div className="emi-column-top">
                    <h2 style={{ color: '#fff' }} >Cadastro de Emitente de NFS-e</h2>
                </div>
                <div className="column-top-btn">
                    <button onClick={handleOpenModalNova}>Novo</button>
                </div>
            </div>

            <div className="emitente-meio">
                <Box sx={{ height: 500, width: '100%', overflowX: 'scroll' }}>
                    <DataGrid
                        autoHeight
                        rows={emitente ?? []}
                        columns={columns}
                        disableColumnResize={true}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick
                    />
                </Box>

            </div>

        </div>
    );
}

export default Emitente;