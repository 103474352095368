
import './Nfse.css';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import ModalNfse from '../Modal/Modal-Nfse.jsx';
import api from '../../api.js';
import ModalDialog from '../Modal/Modal-Dialog.jsx';
import ModalConfirmacao from '../Modal/Modal-Confirmacao.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const NFSe = () => {

    const dataAtual = new Date();
    const dataFormatada = dataAtual.toLocaleDateString('pt-BR');
    const [data,] = useState(dataFormatada);

    const date = new Date();
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const [selectedId, setSelectedId] = useState(null);

    const [openModalNova, setOpenModalNova] = useState(false);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);

    const [nfses, setNfses] = useState([]);
    //    const [primeiroDiaString, setPrimeiroDiaString] = useState(`${primeiroDia.getFullYear()}-${(primeiroDia.getMonth() + 1).toString().length < 2 ? `0${primeiroDia.getMonth() + 1}` : primeiroDia.getMonth() + 1}-${primeiroDia.getDate().toString().length < 2 ? `0${primeiroDia.getDate()}` : primeiroDia.getDate()}T${primeiroDia.getHours().toString().length < 2 ? `0${primeiroDia.getHours()}` : primeiroDia.getHours()}:${primeiroDia.getMinutes().toString().length < 2 ? `0${primeiroDia.getMinutes()}` : primeiroDia.getMinutes()}`);
    //    const [lastDayDate, setLastDayDate] = useState(`${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().length < 2 ? `0${lastDay.getMonth() + 1}` : lastDay.getMonth() + 1}-${lastDay.getDate().toString().length < 2 ? `0${lastDay.getDate()}` : lastDay.getDate()}T${lastDay.getHours().toString().length < 2 ? `0${lastDay.getHours()}` : lastDay.getHours()}:${lastDay.getMinutes().toString().length < 2 ? `0${lastDay.getMinutes()}` : lastDay.getMinutes()}`);

    const [primeiroDiaString, setPrimeiroDiaString] = useState(`${primeiroDia.getFullYear()}-${(primeiroDia.getMonth() + 1).toString().length < 2 ? `0${primeiroDia.getMonth() + 1}` : primeiroDia.getMonth() + 1}-${primeiroDia.getDate().toString().length < 2 ? `0${primeiroDia.getDate()}` : primeiroDia.getDate()}`);
    const [lastDayDate, setLastDayDate] = useState(`${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().length < 2 ? `0${lastDay.getMonth() + 1}` : lastDay.getMonth() + 1}-${lastDay.getDate().toString().length < 2 ? `0${lastDay.getDate()}` : lastDay.getDate()}`);

    const [total, setTotal] = useState(0);
    const [filtro, setFiltro] = useState("0");
    const [origem, setOrigem] = useState("");
    const [operacao, setOperacao] = useState("");
    const [titulo, setTitulo] = useState("");


    function handleCloseModalNova() {
        setOpenModalNova(false);
        setSelectedId(null);
        setOperacao("");
        handleFiltro();
    }

    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    function handleCloseModalDialog() {
        setOpenModalDialog(false);
    }

    function handleOpenModalDialog(e) {
        setSelectedId(e);
        setOpenModalDialog(true);
    }

    const handleOpenModalConfirmacao = (e) => {
        setOrigem('NFSe');
        setOperacao('Excluir');
        setTitulo('Confirma a exclusão da NFSe ?')
        setSelectedId(e);
        setOpenModalConfirmacao(true);
    }

    const handleCloseModalConfirmacao = () => {
        setOpenModalConfirmacao(false);
        setOperacao("");
    }

    const handleEnviarNfse = async () => {
        setOpenModalDialog(false);
        try {
            const retorno = await api.put("/nfseEnviar/" + selectedId);
            alert(retorno[0]);
            handleFiltro();
        } catch (error) {
            console.log(error);
        }
    }

    const handleConsultarNFSe = async (id) => {
        try {
            const consulta = await api.get('/nfsePdf/' + id);
            window.open(consulta.data, '_blank'); // '_blank' abre em uma nova aba        
        } catch (error) {

        }
    }

    const handleEditarNFSe = (id) => {
        try {
            setSelectedId(id);
            setOperacao("Editar");
            setOpenModalNova(true);
        } catch (error) {
            alert(error);
        }
    }

    const openRepetirNFSe = async (id) => {
        setOrigem('NFSe');
        setOperacao('Repetir');
        setTitulo('Confirma a replicação da NFSe ?')
        setSelectedId(id);
        setOpenModalConfirmacao(true);
    }

    function formatDate(inputDate) {
        // Dividir a string de entrada no formato dd/mm/yyyy
        let [day, month, year] = inputDate.split('/');

        // Obter a data atual para extrair horas, minutos e segundos
        let currentDate = new Date();
        let hours = String(currentDate.getHours()).padStart(2, '0');
        let minutes = String(currentDate.getMinutes()).padStart(2, '0');
        let seconds = String(currentDate.getSeconds()).padStart(2, '0');

        // Retornar a data no novo formato yyyy-mm-dd HH:mm:ss
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const repeteNFSe = async (id) => {
        try {
            const nota = await api.get('/nfse/' + id);
            const obj =
            {
                id_emitente: nota.data[0].id_emitente,
                id_cliente: nota.data[0].id_cliente,
                id_servico: nota.data[0].id_servico,
                id_cidade: nota.data[0].id_cidade,
                numero: 123,
                valor: nota.data[0].valor,
                descricao: nota.data[0].descricao,
                data: formatDate(data),
                status: 'Pendente',
                id_faturamento: 0
            }
            const response = await api.post("/createNfse", obj);
            alert('Dados inseridos com sucesso:', response.data);
            handleFiltro();
        } catch (error) {
            console.error('Erro ao incluir os dados:', error);
        }
    }

    const handleExcluirNFSe = async (id) => {
        await api.delete('/nfse/' + id);
        handleFiltro();
    }

    const columns = [
        {
            field: 'editar',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                params.row.status !== "Pendente" ? (
                    <button className='btn-editar-nfse' style={{ opacity: 0.3 }}>
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                ) : (
                    <button className='btn-editar-nfse' onClick={() => handleEditarNFSe(params.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                )
            )
        },
        { field: 'cliente', headerName: 'Cliente', flex: 0.7, maxWidth: 250 },
        { field: 'emitente', headerName: 'Emitente', flex: 0.7, maxWidth: 250 },
        {
            field: 'data',
            headerName: 'Data',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        {
            field: 'valor', headerName: 'Valor', flex: 0.5, type: 'number',
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (value) => {
                return new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                }).format(value);
            }
        },
        { field: 'status', headerName: 'Status', width: 80 },
        {
            field: 'enviar',
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.status === "Pendente" ? (
                    <button className='btn-enviar-nfse' onClick={() => handleOpenModalDialog(params.id)}>
                        Enviar
                    </button>
                ) : (
                    <button className='btn-enviar-nfse' style={{ opacity: 0.1 }}>
                        Enviar
                    </button>
                )
            )
        },
        {
            field: 'consultar',
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.status !== "Pendente" ? (
                    <button className='btn-concluir-nfse' onClick={() => handleConsultarNFSe(params.id)}>
                        Consultar
                    </button>
                ) : (
                    <button className='btn-concluir-nfse' style={{ opacity: 0.1 }}>
                        Consultar
                    </button>
                )
            )
        },
        {
            field: "cancelar",
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.status === "Enviada" ? (
                    <button className='btn-cancelar-nfse' onClick={() => handleButtonCancelar(params.id)}>
                        Cancelar
                    </button>
                ) : (
                    <button className='btn-cancelar-nfse' style={{ opacity: 0.1 }}>
                        Cancelar
                    </button>
                )
            )
        },
        {
            field: "repetir",
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.status !== "Pendente" ? (
                    < button className='btn-repetir-nfse' onClick={() => openRepetirNFSe(params.id)}>
                        Repetir
                    </button >
                ) : (
                    <button className='btn-repetir-nfse' style={{ opacity: 0.1 }}>
                        Repetir
                    </button>
                )

            )
        },
        {
            field: "excluir",
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.status === "Pendente" ? (
                    < button className='btn-excluir-nfse' onClick={() => handleOpenModalConfirmacao(params.id, params.row.status)}>
                        Excluir
                    </button >
                ) : (
                    <button className='btn-excluir-nfse' style={{ opacity: 0.1 }}>
                        Excluir
                    </button>
                )

            )
        }

    ];

    // Select nfes
    useEffect(() => {
        (async () => {
            try {
                const { data: nfses } = await api.get("/nfse/" + primeiroDiaString + "/" + lastDayDate + "/Todas");
                if (nfses) {
                    setNfses(nfses);
                    let iptot = 0;
                    nfses.map((nfe) => {
                        iptot = iptot + parseFloat(nfe.valor);
                        return iptot;
                    });
                    let numeroComDecimais = iptot.toFixed(2);
                    let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                    setTotal(numeroFormatadoComDecimais);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [setNfses, primeiroDiaString, lastDayDate]);

    const handleButtonCancelar = async (event) => {
        try {
            alert(event);
            const ret = await api.delete("/cancelarNfse/" + event);
            alert(ret[0]);
            handleFiltro();
        } catch (error) {
            console.log(error);
        }
    }

    const handleFiltro = async (e) => {
        let status = "Todas";
        if (filtro === "1") {
            status = "Pendente"
        }
        if (filtro === "2") {
            status = "Enviada"
        }
        if (filtro === "3") {
            status = "Cancelada"
        }
        try {
            setNfses([]);
            const { data: nfses } = await api.get("/nfse/" + primeiroDiaString + "/" + lastDayDate + "/" + status);
            if (nfses) {
                setNfses(nfses);
                let iptot = 0;
                nfses.map((nfe) => {
                    iptot = iptot + parseFloat(nfe.valor);
                    return iptot;
                });
                let numeroComDecimais = iptot.toFixed(2);
                let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                setTotal(numeroFormatadoComDecimais);
            }
        } catch (error) {
            console.log(error);
        }
    }

    function handlePrimeiraData(e) {
        const resultArray = e.substring(0, 10) + ' 00:00:01';
        setPrimeiroDiaString(resultArray);
    }

    function handleUltimaData(e) {
        const resultArray = e.substring(0, 10) + ' 23:59:59';
        setLastDayDate(resultArray);
    }

    const filtroStatus = ['Todas', 'Pendentes', 'Enviadas', 'Canceladas'];

    function handleSetStatus(e) {
        setFiltro(e);
    }

    function getRowClassName(params) {
        const status = params.row.status; // Suponha que a coluna "status" contenha o status da linha
        if (status === 'Pendente') {
            return 'linha-pendente'; // Classe CSS para linhas aprovadas
        } else if (status === 'Cancelada') {
            return 'linha-cancelada'; // Classe CSS para linhas pendentes
        } else if (status === 'Enviada') {
            return 'linha-enviada'; // Classe CSS para linhas pendentes
        } else  {
            return ''; // Classe padrão para outras linhas
        }
    }

    return (
        <div className='container-nfse'>
            <ModalNfse statusModal={openModalNova} onCloseModalNova={handleCloseModalNova} selectedId={selectedId} operacao={operacao} ></ModalNfse>
            <ModalDialog statusModal={openModalDialog} onCloseModalDialog={handleCloseModalDialog} onEnviarNFSe={handleEnviarNfse} selectedId={selectedId} titulo='Confirma o envio da NFSe ?' origem='NFSe'></ModalDialog>
            <ModalConfirmacao statusModal={openModalConfirmacao} onCloseModalConfirmacao={handleCloseModalConfirmacao} onExcluirNFSe={handleExcluirNFSe} onRepetirNFSe={repeteNFSe} selectedId={selectedId} titulo={titulo} origem={origem} operacao={operacao}></ModalConfirmacao>
            <div className="container-top-nfse">
                <div className="column-top-nfse">
                    <h2 style={{ color: '#fff' }} >Gestão de Notas Fiscais</h2>
                </div>
                <div className="column-top-btn">
                    <button onClick={handleOpenModalNova}>Nova NFS-e</button>
                </div>
            </div>
            <div className="nfse-filtro">
                <h2 style={{ padding: 10 }}>Filtros: </h2>
                <TextField
                    id="datetime-inicio"
                    label="Inicial"
                    type="date"
                    defaultValue={primeiroDiaString}
                    onChange={(e) => handlePrimeiraData(e.target.value)}
                    variant="filled"
                    className="nfse-custom-textfield nfse-custom-textfield-height"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <span><h3 style={{ padding: 10 }}>até</h3> </span>
                <TextField
                    id="datetime-final"
                    label="Final"
                    variant="filled"
                    type="date"
                    defaultValue={lastDayDate}
                    onChange={(e) => handleUltimaData(e.target.value)}
                    className="nfse-custom-textfield nfse-custom-textfield-height"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <select className='btn-filtrar'
                    onChange={(e) => handleSetStatus(e.target.value)}
                    required
                >
                    {
                        filtroStatus.map((number, index) => (
                            <option key={index} value={index}>{number}</option>
                        ))
                    }
                </select>
                <button className='btn-filtrar' onClick={handleFiltro} >Filtrar</button>

                <div className='div-total'>
                    <h2>Total do filtro: R$ {total}</h2>
                </div>
            </div>
            <div className="nfse-table">
                <DataGrid
                    rows={nfses}
                    columns={columns}
                    getRowClassName={getRowClassName}
                    
                    disableColumnResize={true}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20, 50]}
                />
            </div>
        </div>
    );
}

export default NFSe;