import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem } from "@mui/material";
import './Modal-dialog.css';
// import { useState } from "react";

function ModalConfirmacao({ statusModal, onCloseModalConfirmacao, selectedId, titulo, onExcluir, onExcluirNFSe, onRepetirNFSe, origem, operacao, onExcluirFaturamento }) {

    const handleClose = () => {
        onCloseModalConfirmacao();
    }

    const handleExcluir = () => {
        onExcluir(selectedId);
        onCloseModalConfirmacao();
    }

    const handleExcluirNFSe = () => {
        onExcluirNFSe(selectedId);
        onCloseModalConfirmacao();
    }

    const handleRepetirNFSe = () => {
        onRepetirNFSe(selectedId);
        onCloseModalConfirmacao();
    }

    const handleExcluirFaturamento = () => {
        onExcluirFaturamento(selectedId);
        onCloseModalConfirmacao();
    }

    return (
        <div>
            <Dialog className="d-container" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>{titulo}</DialogTitle>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Sair</button>
                    {origem === 'Clientes' && (
                        <button className="btn-incluir" onClick={handleExcluir}>Excluir</button>
                    )}
                    {origem === 'NFSe' && operacao === 'Excluir' && (
                        <button className="btn-incluir" onClick={handleExcluirNFSe}>Excluir</button>
                    )}                  
                    {origem === 'NFSe' && operacao === 'Repetir' && (
                        <button className="btn-incluir" onClick={handleRepetirNFSe}>Repetir</button>
                    )}  
                    {origem === 'Faturamento' && operacao === 'Excluir' && (
                        <button className="btn-incluir" onClick={handleExcluirFaturamento}>Excluir</button>
                    )}                
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ModalConfirmacao;