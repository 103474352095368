import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem } from "@mui/material";
import './Modal-dialog.css';
// import { useState } from "react";

function ModalDialog({ statusModal, onCloseModalDialog, onEnviarNFSe,onRepetirNFSe, ativarEmpresa, onBaixarImposto, onExcluirImposto, selectedId, titulo, origem, operacao }) {

    const handleClose = () => {
        onCloseModalDialog();
    }

    const handleEnviar = () => {
        onEnviarNFSe();
    }

    const handleRepetir = () => {
        onRepetirNFSe(selectedId);
    }

    const handleAtivarEmpresa = () => {
        ativarEmpresa();
    }

    const handleBaixarImposto = () => {
        onBaixarImposto();
    }

    const handleExcluirImposto = () => {
        onExcluirImposto();
    }

    return (
        <div>
            <Dialog className="d-container" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>{titulo}</DialogTitle>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Sair</button>
                    {origem === 'NFSe' && (
                        <button className="btn-incluir" onClick={handleEnviar}>Enviar</button>
                    )}
                    {origem === 'RepetirNFSe' && (
                        <button className="btn-incluir" onClick={handleRepetir}>Repetir</button>
                    )}
                    {origem === 'Emitente' && (
                        <button className="btn-incluir" onClick={handleAtivarEmpresa}>Ativar Empresa'</button>
                    )}
                    {origem === 'Imposto' && operacao != 'Excluir' && (
                        <button className="btn-incluir" onClick={handleBaixarImposto}>Baixar'</button>
                    )}
                    {origem === 'Imposto' && operacao === 'Excluir' && (
                        <button className="btn-incluir" onClick={handleExcluirImposto}>Excluir'</button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ModalDialog;