import './Faturamento.css';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid, useGridApiRef  } from '@mui/x-data-grid';
import api from '../../api.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ModalFaturamento from '../Modal/Modal-faturamento.jsx';
import ModalGeracao from '../Modal/Modal-geracao.jsx';
import ModalConfirmacao from '../Modal/Modal-Confirmacao.jsx'
import imgBoleto from '../../img/imgBoleto.png';
import imgNfe from '../../img/nfe48.png';
import { toast } from 'react-toastify';

const Faturamento = () => {

    const date = new Date();
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const apiRef = useGridApiRef(); 

    const [selectedId, setSelectedId] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [openModalGeracao, setOpenModalGeracao] = useState(false);
    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);

    const [faturamentos, setFaturamentos] = useState([]);
    const [primeiroDiaString, setPrimeiroDiaString] = useState(`${primeiroDia.getFullYear()}-${(primeiroDia.getMonth() + 1).toString().length < 2 ? `0${primeiroDia.getMonth() + 1}` : primeiroDia.getMonth() + 1}-${primeiroDia.getDate().toString().length < 2 ? `0${primeiroDia.getDate()}` : primeiroDia.getDate()}`);
    const [lastDayDate, setLastDayDate] = useState(`${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().length < 2 ? `0${lastDay.getMonth() + 1}` : lastDay.getMonth() + 1}-${lastDay.getDate().toString().length < 2 ? `0${lastDay.getDate()}` : lastDay.getDate()}`);
    const [total, setTotal] = useState(0);
    const [filtro, setFiltro] = useState("0");
    const [operacao, setOperacao] = useState("");

    const filtroStatus = ['Todos', 'Abertos', 'Recebidos', 'Bonificados'];

    const [selectedRows, setSelectedRows] = useState([]);

    const columns = [
        {
            field: 'editar',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                params.row.status !== "Aberto" ? (
                    <button style={{ opacity: 0.3 }}>
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                ) : (
                    <button onClick={() => handleEditarNFSe(params.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                )
            )
        },
        { field: 'nome', headerName: 'Cliente', flex: 0.7, maxWidth: 250 },
        { field: 'descricao', headerName: 'Descricao', flex: 0.7, maxWidth: 250 },
        {
            field: 'lancamento',
            headerName: 'Lançamento',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        {
            field: 'vencimento',
            headerName: 'Vencimento',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        {
            field: 'valor', headerName: 'Valor', flex: 0.5, type: 'number',
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (value) => {
                return new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                }).format(value);
            }
        },
        { field: 'status', headerName: 'Status', width: 80 },
        {
            field: 'boleto',
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.status === "Aberto" ? (
                    <img src={imgBoleto} style={{ width: 50, height: 50 }} onClick={() => handleConsultarNFSe(params.id)}></img>
                ) : (
                    <img src={imgBoleto} className='btn-concluir-nfses' style={{ width: 50, height: 50, opacity: 0.1 }}></img>
                )
            )
        },
        {
            field: 'nfse',
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.status === "Aberto" ? (
                    <img src={imgNfe} className='btn-concluir-nfses' style={{ width: 50, height: 50 }} onClick={() => handleConsultarNFSe(params.id)} ></img>
                ) : (
                    <img src={imgNfe} className='btn-concluir-nfses' style={{ width: 50, height: 50, opacity: 0.1 }}></img>
                )
            )
        },
        {
            field: "excluir",
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.status === "Aberto" ? (
                    < button className='btn-excluir-fat' onClick={() => handleOpenModalConfirmacao(params.id, params.row.status)}>
                        Excluir
                    </button >
                ) : (
                    <button className='btn-excluir-fat' style={{ opacity: 0.1 }}>
                        Excluir
                    </button>
                )

            )
        }

    ];

    // Select faturamentos
    useEffect(() => {
        (async () => {
            try {
                const { data: faturamentos } = await api.get("/faturamentos/" + primeiroDiaString + "/" + lastDayDate + "/Todos");
                if (faturamentos) {
                    setFaturamentos(faturamentos);
                    let iptot = 0;
                    faturamentos.map((fat) => {
                        iptot = iptot + parseFloat(fat.valor);
                        return iptot;
                    });
                    let numeroComDecimais = iptot.toFixed(2);
                    let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                    setTotal(numeroFormatadoComDecimais);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [setFaturamentos, primeiroDiaString, lastDayDate]);

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleOpenModalGeracao = () => {
        setOperacao("Geracao");
        setOpenModalGeracao(true);
    }

    const handleOpenModalBoleto = () => {
        if (selectedRows.length > 0) {
            setOperacao("Boleto");
            setOpenModalGeracao(true);
        } else {
            toast.warning('Favor selecionar os registros que você quer emitir boleto.');
        }
    }

    const handleOpenModalNFSe = () => {
        if (selectedRows.length > 0) {
            setOperacao("NFSe");
            setOpenModalGeracao(true);
        } else {
            toast.warning('Favor selecionar os registros que você quer emitir nfs-e.');
        }
    }

    const handleCloseModalGeracao = () => {
        setOpenModalGeracao(false);
        handleFiltro();
    }

    const handleOpenModalConfirmacao = (id, st) => {
        setSelectedId(id);
        setOpenModalConfirmacao(true);
    }

    const handleConsultarNFSe = (id) => {
        setSelectedId(id);
        alert(id);
    }

    const handleEditarNFSe = (id) => {
        setSelectedId(id);
    }

    function handlePrimeiraData(e) {
        const resultArray = e.substring(0, 10) + ' 00:00:00';
        setPrimeiroDiaString(resultArray);
    }

    function handleUltimaData(e) {
        const resultArray = e.substring(0, 10) + ' 00:00:00';
        setLastDayDate(resultArray);
    }

    function handleSetStatus(e) {
        setFiltro(e);
    }

    const handleFiltro = async (e) => {
        let status = "Todos";
        if (filtro === "1") {
            status = "Aberto"
        }
        if (filtro === "2") {
            status = "Recebido"
        }
        if (filtro === "3") {
            status = "Bonificado"
        }
        try {
            setFaturamentos([]);
            const { data: faturamentos } = await api.get("/faturamentos/" + primeiroDiaString + "/" + lastDayDate + "/" + status);
            //const { data: faturamentos } = await api.get("/faturamentos");
            if (faturamentos) {
                setFaturamentos(faturamentos);
                let iptot = 0;
                faturamentos.map((fat) => {
                    iptot = iptot + parseFloat(fat.valor);
                    return iptot;
                });
                let numeroComDecimais = iptot.toFixed(2);
                let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                setTotal(numeroFormatadoComDecimais);
            }
        } catch (error) {
            console.log(error);
        }
    }

    function getRowClassName(params) {
        const status = params.row.status; // Suponha que a coluna "status" contenha o status da linha
        if (status === 'Aberto') {
            return 'linha-aberto'; // Classe CSS para linhas aprovadas
        } else if (status === 'Recebido') {
            return 'linha-recebido'; // Classe CSS para linhas pendentes
        } else {
            return ''; // Classe padrão para outras linhas
        }
    }

    const handleCloseModalConfirmacao = () => {
        setOpenModalConfirmacao(false);
    }

    const handleExcluirFat = async () => {
        try {
            const exclui = await api.delete('/faturamento/' + selectedId);
            toast.success('Registro excluido com sucesso...');
            handleFiltro();
        } catch (error) {
            toast.error('Problema na exclusão..' + error);
        }
    }

    const handleEmitirNFSe = async () => {
        toast.success(JSON.stringify(selectedRows));
        if (selectedRows) {
            try {
                //const retorno = await api.put("/nfseEnviar/" + selectedRows);
            } catch (error) {

            }
        }
    }

    // para pegar os dados da linha inteira digitada
    const handleRowSelection = (selectionModel, api) => {
        const selectedRowsData = selectionModel.map((id) => api.current.getRow(id));
        setSelectedRows(selectedRowsData); // Armazena os dados completos das linhas selecionadas
    };

    return (
        <div className='container-faturamento'>
            <ModalFaturamento statusModal={openModal} onCloseModal={handleCloseModal}></ModalFaturamento>
            <ModalGeracao statusModal={openModalGeracao} onCloseModalGeracao={handleCloseModalGeracao} operacao={operacao} emitirNFSe={handleEmitirNFSe} registrosSelected={selectedRows} ></ModalGeracao>
            <ModalConfirmacao statusModal={openModalConfirmacao} onCloseModalConfirmacao={handleCloseModalConfirmacao} onExcluirFaturamento={handleExcluirFat} titulo='Confirma a exclusão ?' operacao='Excluir' origem='Faturamento'></ModalConfirmacao>
            <div className="container-top-faturamento">
                <div className="column-top-faturamento">
                    <h2 style={{ color: '#fff' }} >Faturamento</h2>
                </div>
                <div className="btn-novo-fat">
                    <button onClick={handleOpenModal}>Novo</button>
                </div>
                <button className='btn-gerar-fat' onClick={handleOpenModalGeracao} style={{ border: "none", width: 150 }} >Gerar Faturamento</button>
                <button className='btn-gerar-fat' onClick={handleOpenModalBoleto} style={{ border: "none", width: 120 }} >Emitir Boletos</button>
                <button className='btn-gerar-fat' onClick={handleOpenModalNFSe} style={{ border: "none", width: 100 }} >Emitir NFS-e</button>
            </div>
            <div className="nf-filtro-fat">
                <h2 style={{ padding: 10 }}>Filtros: </h2>
                <TextField
                    id="datetime-inicio"
                    label="Inicial"
                    type="date"
                    defaultValue={primeiroDiaString}
                    onChange={(e) => handlePrimeiraData(e.target.value)}
                    variant="filled"
                    className="fat-custom-textfield fat-custom-textfield-height"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <span><h3 style={{ padding: 10 }}>até</h3> </span>
                <TextField
                    id="datetime-final"
                    label="Final"
                    variant="filled"
                    type="date"
                    defaultValue={lastDayDate}
                    onChange={(e) => handleUltimaData(e.target.value)}
                    className="fat-custom-textfield fat-custom-textfield-height"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <select className='btn-filtrar-fat'
                    onChange={(e) => handleSetStatus(e.target.value)}
                    required
                >
                    {
                        filtroStatus.map((number, index) => (
                            <option key={index} value={index}>{number}</option>
                        ))
                    }
                </select>
                <button className='btn-filtrar-fat' onClick={handleFiltro} >Filtrar</button>

                <div className='div-total-fat'>
                    <h2>Total do filtro: R$ {total}</h2>
                </div>
            </div>

            <div className="nf-table-fat">
                <DataGrid
                    columns={columns}
                    checkboxSelection
                    rows={faturamentos}
                    isRowSelectable={(params) => {
                        // Exemplo de condição: habilitar o checkbox se o valor de uma coluna "status" for diferente de 'Recebido'
                        return params.row.status != 'Recebido';
                    }}
                    disableColumnResize={true}
                    apiRef={apiRef} // Passando o apiRef para o DataGrid
                    getRowClassName={getRowClassName}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20, 50]}
                    onRowSelectionModelChange={(selection) => {
                        handleRowSelection(selection, apiRef) // Passando a seleção e apiRef para pegar os dados      
                       // setSelectedRows(selection); // Armazena as linhas selecionadas no estado                        
                    }}
                />
            </div>
        </div>
    )

}

export default Faturamento;