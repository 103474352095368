
import "./NavbarStyles.css"
import Referencias from "../Select/mes-referencia.jsx";

function Navbar() {
   
    return (
        <div className="div-container">
            <nav>
                <a>
                    <svg id="logo-15" width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M24.5 12.75C24.5 18.9632 19.4632 24 13.25 24H2V12.75C2 6.53679 7.03679 1.5 13.25 1.5C19.4632 1.5 24.5 6.53679 24.5 12.75Z" className="ccustom" fill="#17CF97"></path> <path d="M24.5 35.25C24.5 29.0368 29.5368 24 35.75 24H47V35.25C47 41.4632 41.9632 46.5 35.75 46.5C29.5368 46.5 24.5 41.4632 24.5 35.25Z" className="ccustom" fill="#17CF97"></path> <path d="M2 35.25C2 41.4632 7.03679 46.5 13.25 46.5H24.5V35.25C24.5 29.0368 19.4632 24 13.25 24C7.03679 24 2 29.0368 2 35.25Z" className="ccustom" fill="#17CF97"></path> <path d="M47 12.75C47 6.53679 41.9632 1.5 35.75 1.5H24.5V12.75C24.5 18.9632 29.5368 24 35.75 24C41.9632 24 47 18.9632 47 12.75Z" className="ccustom" fill="#17CF97"></path> </svg>
                </a>
                <div>
                    <ul id="navbar">
                        <li><a href="/home">Home</a></li>
                        <li><a>Cadastros</a></li>
                        <li><a>Ordens</a></li>
                        <li><a>Relatórios</a></li>
                    </ul>
                </div>

            </nav>

            <div className="div-body">
                <div className="div-top">
                    <Referencias />            
                    <span className="spn">Impostos do mês: R$ 12.000,00</span>
                    <span className="spn">Despesas variaveis do mês: R$ 8.000,00</span>
                    <span className="spn">Pró-labore do mês: R$ 10.000,00</span>
                </div>
                <div className="div-top">
                    <span className="spn2">Total de notas emitidas: R$ 35.000,00</span>
                    <span className="spn2">Saldo do mês: R$ 5.000,00 ++</span>
                </div>
            </div>

            <div className="div-body">
                <div className="div-top">
                    <span className="spn">Emitir NFS-e <button>Emitir</button></span>
                    <span className="spn">Consultar / Cancelar NFS-e <button>Consultar</button></span>
                    <span className="spn">Relação NFS-e no período <button>Relatório</button></span>
                </div>
                <div className="div-top">
                    <span className="spn2">Total de notas emitidas: R$ 35.000,00</span>
                    <span className="spn2">Saldo do mês: R$ 5.000,00 ++</span>
                </div>
            </div>


        </div>
    );
}


export default Navbar;