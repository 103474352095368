import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Navbar from "./components/Navbar/Navbar.jsx";
import Home from "./pages/Home/home.jsx";
import PriveteRoute from "./components/Private-route/private-route.jsx";
import Login from "./pages/Login/login.jsx";
import Lembrete from "./pages/Login/lembrete.jsx";
import Emitente from "./components/Emitente/Emitente.jsx";
import Clientes from "./components/Clientes/Clientes.jsx";
import Impostos from "./components/Impostos/Impostos.jsx";
import Despesas from "./components/Despesas/Despesa.jsx";
import { Register } from "./pages/Register/Register.jsx";


function Rotas() {
   return <BrowserRouter>
        <Routes>
            <Route path="/home" element={<PriveteRoute><Home /></PriveteRoute>}></Route>
            <Route path="/navbar" element={<PriveteRoute><Navbar /></PriveteRoute>}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route path="/lembrete" element={<Lembrete />}></Route>
            <Route path="/emitente" element={<Emitente />}></Route>
            <Route path="/clientes" element={<Clientes />}></Route>
            <Route path="/imposto" element={<Impostos />}></Route>
            <Route path="/despesa" element={<Despesas />}></Route>
            <Route path="/" element={<Login />}></Route>
        </Routes>
        <ToastContainer autoClose="2000" />
   </BrowserRouter>
}

export default Rotas;