import './Clientes.css';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import ModalCliente from '../Modal/Modal-Cliente.jsx';
import ModalDialog from '../Modal/Modal-Dialog.jsx';
import api from '../../api.js';
import { Box } from '@mui/material';
import ModalConfirmacao from '../Modal/Modal-Confirmacao.jsx';

function Clientes() {

    const [openModalNova, setOpenModalNova] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);

    const columns = [
        { field: 'cnpjcpf', headerName: 'Cnpj/Cpf', width: 140 },
        { field: 'nome', headerName: 'Nome', width: 300, flex: 1, },
        { field: 'nome_cidade', headerName: 'Cidade', width: 100, flex: 1 },
        { field: 'nome_estado', headerName: 'UF', width: 80, flex: 1 },
        { field: 'ultima_nota_emitida', headerName: 'Última NFSe', width: 100, flex: 1, editable: true },
        {
            field: 'repetir',
            headerName: '',
            width: 120,
            align: 'center',
            renderCell: (params) => (
                params.row.ultima_nota_emitida === "" || params.row.ultima_nota_emitida === null ? (
                    <button className='btn-repetir' style={{ opacity: 0.3 }}>
                        Repetir NFSe
                    </button>
                ) : (
                    <button className='btn-repetir' onClick={() => handleRepetir(params.id)}>
                        Repetir NFSe
                    </button>
                )

            )
        },
        {
            field: 'editar',
            headerName: '',
            width: 120,
            renderCell: (params) => (
                <button className='btn_enviar' onClick={() => handleButtonEditar(params.id)}>
                    Editar
                </button>
            )
        },
        {
            field: 'deletar',
            headerName: '',
            width: 120,
            renderCell: (params) => (
                <button className='btn_excluir' onClick={() => handleButtonExcluir(params.id)}>
                    Excluir
                </button>
            )
        }
    ];

    const onExcluir = (async (id) => {
        try {
            const temNota = await api.get("/clienteNota/" + id);
            if (temNota.data != '0') {
                alert('Cliente possui NFSe, não pode ser excluido.');
            } else {
                const exclui = await api.delete("/cliente/" + id);
                alert(exclui.data);
                atualizaTela();
            }
        } catch (error) {
            alert('Erro:' + error);
        }
    })

    const handleButtonExcluir = (async (id) => {
        setSelectedId(id);
        setOpenModalConfirmacao(true);
    })

    function handleButtonEditar(id) {
        setSelectedId(id);
        setOpenModalNova(true);
    }

    function handleRepetir(id) {
        setSelectedId(id);
        setOpenModalDialog(true);
    }

    const handleRepetirNfse = async (id) => {
        setOpenModalDialog(false);
        try {
            const repetiNota = await api.get("/clienteNota/" + selectedId);
            if (repetiNota.status === 200) {
                const repetir = await api.put("/repetirNFSe/" + repetiNota.data);
                if (repetir.status === 200) {
                    alert('Nota replicada com sucesso.')
                } else {
                    alert('Problema na replicação: ' + repetir.data);
                }
            }
            //alert(repetir.data);
            //handleFiltro();
        } catch (error) {
            console.log(error);
        }
    }


    function handleCloseModalNova() {
        setSelectedId(null);
        setOpenModalNova(false);
        api.get("/clientes").then(({ data }) => {
            if (data)
                setCliente(data);
        }).catch((err) =>
            console.error("ops! ocorreu um erro" + err));
    }

    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    const atualizaTela = () => {
        (async () => {
            try {
                const { data } = await api.get("/clientes");
                if (data)
                    setCliente(data);
            } catch (e) {
                console.log("deu certo não");
            }
        })();
    }

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/clientes");
                if (data)
                    setCliente(data);
            } catch (e) {
                console.log("deu certo não");
            }
        })();
    }, []);

    function handleCloseModalDialog() {
        setOpenModalDialog(false);
    }

    function handleCloseModalConfirmacao() {
        setOpenModalConfirmacao(false);
    }

    return (
        <div className="container-clientes">
            <ModalCliente statusModal={openModalNova} onCloseModal={handleCloseModalNova} selectedId={selectedId} ></ModalCliente>
            <ModalDialog statusModal={openModalDialog} onCloseModalDialog={handleCloseModalDialog} onRepetirNFSe={handleRepetirNfse} selectedId={selectedId} titulo='Confirma geração dessa NFSe ?' origem='RepetirNFSe'></ModalDialog>
            <ModalConfirmacao statusModal={openModalConfirmacao} onCloseModalConfirmacao={handleCloseModalConfirmacao} onExcluir={onExcluir} selectedId={selectedId} titulo='Confirma a exclusão' origem='Clientes'></ModalConfirmacao>
            <div className="cli-container-top">
                <div className="cli-column-top">
                    <h2 style={{ color: '#fff' }} >Cadastro de Clientes</h2>
                </div>
                <div className="column-top-btn">
                    <button onClick={handleOpenModalNova}>Novo</button>
                </div>
            </div>

            <div className="cliente-meio">
                <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                    <DataGrid
                        autoHeight
                        rows={cliente ?? []}
                        columns={columns}
                        // checkboxSelection // Habilita a seleção por checkboxes
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick={false} // Permite a seleção ao clicar na linha
                    // rowSelectionModel={['id']} // Substitua por um array com os IDs das linhas que deseja selecionar
                    />
                </Box>
            </div>
        </div>
    );
}

export default Clientes;